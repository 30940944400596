import React, { useEffect } from "react";
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { getInfo } from "../services/locker.service";
import { useStore } from "../config/store-context";
import Loading from '../components/loading-spinner';

const QrRoute = Loadable({
  loader: () => import('./qr/qr.route'),
  loading: Loading,
});

const AboutRoute = Loadable({
  loader: () => import('./about/about.route'),
  loading: Loading,
});

const InputRoute = Loadable({
  loader: () => import('./input/input.route'),
  loading: Loading,
});

const ErrorRoute = Loadable({
  loader: () => import('./error/error.route'),
  loading: Loading,
});

const IssuedInfoRoute = Loadable({
  loader: () => import('./issued-info/issued-info.route'),
  loading: Loading,
});

const LockerRoute = Loadable({
  loader: () => import('./locker/locker.route'),
  loading: Loading,
});

const NotFound = () => <h2>NotFound</h2>;

export const Routes = () => {
  const { dispatch, state: { orderCode } } = useStore()

  useEffect(() => {
    if (orderCode) {
      getInfo(orderCode, dispatch)
    }
  }, [orderCode, dispatch])

  const routes = [
    { path: '/drop/:orderCode', component: AboutRoute },
    { path: '/qr/:orderCode', component: QrRoute },
    { path: '/input/:orderCode', component: InputRoute },
    { path: '/issued/:orderCode', component: IssuedInfoRoute },
    { path: '/locker/:orderCode', component: LockerRoute },
    { path: '/error/:orderCode', component: ErrorRoute },
    { path: '*', component: NotFound }
  ]

  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  )
}
