import history from "../config/history";

const baseUrl = 'https://platform.tastamat.com/platform/v1/rest/opener';

async function _getOrderInfo(code) {
  const response = await fetch(`${baseUrl}/info/${code}`)
  return await response.json()
}

export function getInfo(code, dispatch) {
  return new Promise((resolve) => {
    _getOrderInfo(code)
      .then(async res => {
        await dispatch({ type: 'SET_INFO', payload: res });
        const endOfIndex = res.locker && res.locker.index.slice(-2);
        dispatch({ type: 'SET_TYPE', payload: parseInt(endOfIndex) < 31 ? 'old' : 'new' })
        resolve()
      })
      .catch(error => handleError(error, code))
  })
}

export async function openIntercityLocker(dto) {
  const response = await fetch(`${baseUrl}/drop/cdek`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dto)
  })

  return await response.json()
}

export async function openLocker(dto) {
  const response = await fetch(`${baseUrl}/drop`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dto)
  })

  return await response.json()
}

export async function openIntercom(dto) {
  const response = await fetch(`${baseUrl}/open-intercom`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dto)
  })

  return await response.json()
}


export function handleError(error, orderCode) {
  let err = {};
  try { err = JSON.parse(error.response.data.message)} catch (e) {}
  history.push({
    pathname: `/error/${orderCode}`,
    state: { error: err }
  })
}
